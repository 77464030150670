<template>
  <div style="padding: 20px;">
    <form-create
      v-model="fapi"
      :rule="rule"
      :option="option"
      @submit="onSubmit"
    ></form-create>
  </div>
</template>

<script>
// import formCreate from "@form-create/element-ui";

//引入的接口
import {get_form} from '@/api/system'

// //FcDesigner 生成的`JSON`
// const FcDesignerRule = '[{"type":"input","field":"cuk5qqdw3umc","title":"输入框","info":"","_fc_drag_tag":"input","hidden":false,"display":true}]';
//
// //FcDesigner 生成的`options`
// const FcDesignerOptions = '{"form":{"labelPosition":"right","size":"mini","labelWidth":"125px","hideRequiredAsterisk":false,"showMessage":true,"inlineMessage":false}}';

export default {
  components:{
    // formCreate
  },
  data () {
    return {
      fapi: null,
      rule: [],
      option: {}
    }
  },

  mounted() {
    this.getForm();
  },
  methods: {
    getForm(){
      get_form().then(res => {
        console.log(res)
        // let a = '';
        // a = res.data.rule;
        // console.log(a)
        // this.rule = formCreate.parseJson(res.data.body);
        // this.option = formCreate.parseJson(res.data.config);
        // 隐藏提交按钮
        // res.data.config.submitBtn = false;
        // 修改表单大小
        res.data.config.form.size = 'medium';
        console.log(res)
        // 获取规则
        this.rule = res.data.body;
        this.option = res.data.config;
      })
    },

    onSubmit (formData) {
      console.log(formData)
      // 子组件给父组件传值
      this.$emit('formData',formData);
      //todo 提交表单
    }
  }
}
</script>
